import React from 'react'
import Hero from '../components/Hero'
import PatientBehaviorCard from '../components/PatientBehaviorCard'
import StatCard from '../components/StatCard'
import CallToAction from '../components/CallToAction'
import { FaArrowUp } from 'react-icons/fa'
import iconBottleSmile from '../images/icon-bottle-smile.svg'
import iconText from '../images/icon-text.svg'
import iconAlert from '../images/icon-alert.svg'

export default function healthplansAndPayersPage() {
  return (
    <main className="healthplans-and-payers">
      <Hero
        color="blue"
        image="data-devices"
        heading="Real-time connection and monitoring for continuous care"
        text="High-touch, remote adherence solutions to support better outcomes."
      />
      <div className="page-section">
        <div className="page-section-content stay-on-track">
          <h2>Patients need help to stay on track</h2>
        </div>
        <div className="page-section-content adherence">
          <p>
            Poor medication adherence presents costly and avoidable challenges to the healthcare system. The Aidia
            System™ provides you with innovative technology to better support patient adherence and potentially improve
            outcomes. With real-time monitoring and connection to care for early intervention, together we can slow the
            progression of illness, reduce expensive emergency care, and prevent unnecessary hospitalizations.
          </p>
        </div>
        <div className="page-section-content patient-populations">
          <h2>AdhereTech supports your patient populations every day at every dose </h2>
        </div>
        <div className="page-section-content patient-behavior-cards">
          <PatientBehaviorCard
            image={iconBottleSmile}
            altText="icon bottle smile"
            content="An easy-to-use system that has been shown to improve adherence and connects with patients every day, between healthcare provider visits and prescription refills."
          />
          <PatientBehaviorCard
            image={iconText}
            altText="icon text"
            content="2-way communication channels with patients monitored by live support specialists provide assistance when needed."
          />
          <PatientBehaviorCard
            image={iconAlert}
            altText="icon text"
            content="Reliable and data-driven escalations to care teams enable real-time care for patients, which may help delay the development of more serious medical issues."
          />
        </div>
      </div>
      <div className="page-section--blue">
        <div className="page-section-content stat-cards__container">
          <div className="stat-cards">
            <StatCard
              headerText={
                <header>
                  15%
                  <FaArrowUp className="arrow-up" size={52} />
                </header>
              }
              content={
                <span className="subtext">
                  increased dose-level adherence<sup>1</sup>
                </span>
              }
            />
            <StatCard
              headerText={
                <header>
                  4.7<span>out of</span> 5
                </header>
              }
              content={
                <span className="subtext">
                  patient satisfaction<sup>2</sup>
                </span>
              }
            />
          </div>
          <div className="references">
            <span>
              <span className="bold">References: 1.</span> Avella Pharmacy Pharmacy. <span className="bold">2.</span>
              <span className="italic"> Journal of Managed Care and Specialty Pharmacy</span> (JMCP).
            </span>
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content call-to-action-grid">
          <CallToAction
            headerText="Partner with us"
            content="Speak with a representative to learn how AdhereTech can optimize your patient support models."
            linkText="Contact Us"
            linkUrl="/contact-us?organizationType=health_plan_payer"
            color="blue"
          />
        </div>
      </div>
    </main>
  )
}
